import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {User} from '../models/user';
import {ConfigService} from './config.service';
import {GoogleAuthServiceService} from './google-auth-service.service';
import {Account} from '../models/account';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
        private http: HttpClient, private config: ConfigService, private googleAuthServiceService: GoogleAuthServiceService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.googleAuthServiceService.googleUserSelected.subscribe((googleUser) => {
      if (this.currentUserSubject.value) {
        this.http.post<any>(this.config.serverUrl +  `/api/users/authenticate`, googleUser)
          .pipe(map(user => {
            this.currentUserSubject.value.code = user.code;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(this.currentUserSubject.value));
            // this.currentUserSubject.next(user);
            return user;
          })).subscribe((user: User) =>  {
            console.log('User: ' + user.email + ' re authenticated');
        });
      }
    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(u: User) {
    return this.http.post<any>(this.config.serverUrl +  `/api/users/authenticate`, u)
      .pipe(map(user => {
        if (user) {
          user.accountName = user.accounts.find(a => a.uuid === user.account).name;
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        if (!user) {
          throw new Error('Invalid credentials');
        }
        return user;
      }));
  }

  selectAccount(account: Account) {
    const user = this.currentUserValue;
    user.account = account.uuid;
    user.accountName = account.name;
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.googleAuthServiceService.signOut();
  }
}
