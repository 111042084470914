import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';
import {Advert} from '../models/advert';
import {Video} from '../models/video';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  getVideo(video: Video) {
    return this.http.get<Video>(
      this.config.serverUrl
        +  '/api/accounts/'
        + this.authenticationService.currentUserValue.account +  '/videos/' + video.id);
  }
}
