import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdsComponent} from './ads/ads.component';
import {AuthGuard} from './helpers/auth-guard';
import {SettingsComponent} from './settings/settings.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {AddAdComponent} from './add-ad/add-ad.component';
import {DatafeedComponent} from './datafeed/datafeed.component';
import {DatafeedConfigurationComponent} from './datafeed-configuration/datafeed-configuration.component';
import {AddDatafeedAdsComponent} from './add-datafeed-ads/add-datafeed-ads.component';
import {EditAdComponent} from './edit-ad/edit-ad.component';

const routes: Routes = [
  { path: '', component: AdsComponent, canActivate: [AuthGuard] },
  { path: 'addAdvert', component: AddAdComponent, canActivate: [AuthGuard] },
  { path: 'advert/:advertId', component: EditAdComponent, canActivate: [AuthGuard] },
  { path: 'addDatafeedAdverts', component: AddDatafeedAdsComponent, canActivate: [AuthGuard] },
  { path: 'datafeed', component: DatafeedComponent, canActivate: [AuthGuard] },
  { path: 'datafeedConfiguration', component: DatafeedConfigurationComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
    /*, enableTracing: true*/
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
