import {GoogleCampaign} from './google-campaign';
import {AdGroup} from './ad-group';
import {TemplateParameter} from './template-parameter';
import {DatafeedProduct} from './datafeed-product';

export class CreateDatafeedAdvertsRequest {
  advertName: string;
  googleCampaign: GoogleCampaign;
  googleAdGroup: AdGroup;
  templateId: number;
  destinationUrl: string;
  displayUrl: string;
  parameters: TemplateParameter[];
  products: DatafeedProduct[];
}
