import {EventEmitter, Injectable} from '@angular/core';
import {GoogleUser} from '../models/google-user';
import {User} from '../models/user';
import {BehaviorSubject, Observable} from 'rxjs';
import {GoogleTargeting} from '../models/google-targeting';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthServiceService {
  auth2: any;
  googleUserSelected = new EventEmitter<User>();

  constructor() {
    const scriptElement = document.createElement('script') as any;
    scriptElement.type = 'text/javascript';
    scriptElement.src = '//apis.google.com/js/platform.js?onload=onGoogleSignInReady';
    scriptElement.async = true;
    scriptElement.defer = true;

    document.getElementsByTagName('body')[0].appendChild(scriptElement);
    window['onGoogleSignInReady'] = () => {
      this.onGoogleSignInInit();
    };
  }

  onGoogleSignInInit(): void {
    const gapi = window['gapi'] as any;

    const onLoad = () => {
      this.auth2 = gapi.auth2.init({
        client_id: '762092863226-laki1gt3k572qtbpj7sapdkf8ou11di1.apps.googleusercontent.com',
        scope: 'openid profile email'
      });

      // Listen for sign-in state changes.
      this.auth2.isSignedIn.listen((val) => {
        // console.log('Signin state changed to ', val);
      });

      // Listen for changes to current user.
      this.auth2.currentUser.listen((userData: any) => {
        console.log('User now: ', userData);
        this.googleUserSelected.emit(this.parseUser(userData));
      });
    };

    gapi.load('auth2', onLoad);
  }

  parseUser(userData: any): User {
    const user = new User();
    user.google = new GoogleUser();
    if (userData.getAuthResponse && userData.getAuthResponse()) {
      user.google.idToken = userData.getAuthResponse().id_token;
    }
    if (userData.getBasicProfile && userData.getBasicProfile()) {
      user.email = userData.getBasicProfile().getEmail();
      user.name = userData.getBasicProfile().getName();
      user.firstName = userData.getBasicProfile().getGivenName();
      user.lastName = userData.getBasicProfile().getFamilyName();
      user.imageUrl = userData.getBasicProfile().getImageUrl();
    }
    return user;
  }

  signIn(callback) {
    this.auth2.signIn().then((userData: any) => {
      if (callback) {
        const user = this.parseUser(userData);
        callback(user);
      }
    }), (e) => {
      console.error('Error', e);
    };
  }

  signInOffline(callback) {
    this.auth2.grantOfflineAccess({
      scope: "openid profile email https://www.googleapis.com/auth/adwords"
    }).then((resp) => {
      if (callback) {
        const user = new User();
        user.google = new GoogleUser();
        user.google.code = resp.code;
        callback(user);
      }
    });
  }

  signOut() {
    try {
      this.auth2.signOut().then(() => {
        console.log('User signed out.');
      });
    } catch (e) {}
  }
}
