import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TemplateParameter} from '../../models/template-parameter';

@Component({
  selector: 'tr-texts-input-field',
  templateUrl: './texts-input-field.component.html',
  styleUrls: ['./texts-input-field.component.css']
})
export class TextsInputFieldComponent implements OnInit, OnChanges {

  constructor() { }

  @Input()
  value: string;
  @Input()
  default: string;
  @Input()
  parameter: TemplateParameter;
  @Output()
  changed = new EventEmitter<string>();

  values: string[];

  ngOnInit() {
    this.values = this.value && this.value.length > 0 ? this.value.split(',') : [];
  }

  ngOnChanges(changes: SimpleChanges) {
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
    if (changes.value && changes.value.currentValue) {
      this.values = this.value && this.value.length > 0 ? this.value.split(',') : [];
    }
  }

  removeValue(value: string) {
    const index: number = this.values.indexOf(value);
    if (index !== -1) {
      this.values.splice(index, 1);
      this.changed.emit(this.values.join(','));
    }
  }

  onChange($event: Event) {
    if ($event.target && $event.target['value'] && $event.target['value'].length > 0) {
      this.values[this.values.length] = $event.target['value'];
      $event.target['value'] = '';
      this.changed.emit(this.values.join(','));
    }
  }

  addValue(value: string) {
    this.values[this.values.length] = value;
    this.changed.emit(this.values.join(','));
  }
}
