import {Component, Input, OnInit} from '@angular/core';
import {Template} from '../../models/template';

@Component({
  selector: 'tr-dynamic-player',
  templateUrl: './dynamic-player.component.html',
  styleUrls: ['./dynamic-player.component.css']
})
export class DynamicPlayerComponent implements OnInit {
  constructor() { }

  @Input() account: string;
  @Input() template: Template;
  @Input() sku: string;
  @Input() parameters: any;
  id: number;
  trpdplayer: any;

  displayPlayer(): void {
    $('#treepodiaPlayerMain' + this.id).show();
    this.trpdplayer.load({
      playerHolder : '#treepodiaPlayerMain' + this.id,
      account: this.account,
      template : this.template.instanceId,
      sku : this.sku,
      parameters : this.parameters || {}
    });
  }

  hidePlayer(): void {
    $('#treepodiaPlayerMain' + this.id).hide();
  }

  ngOnInit() {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = '//static.treepodia.com/dynamicplayer/js/dynamic.video.player.5.0.min.js';
    window['__trpd_dynamic_player_count'] = (window['__trpd_dynamic_player_count'] || 0) + 1;
    this.id = window['__trpd_dynamic_player_count'];

    scriptElement.onload = () => {
      this.trpdplayer = window['DynamicVideoPlayer']();
    };

    scriptElement.onerror = (error: any) => {
      console.error('Couldn\'t load script ' + scriptElement.src);
    };

    document.getElementsByTagName('body')[0].appendChild(scriptElement);
  }
}
