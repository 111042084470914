export class DatafeedProduct {
  id: number;
  sku: string;
  name: string;
  category: string;
  images: string[];
  selected: boolean;
  views: number;
  purchases: number;
}
