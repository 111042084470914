import {Component, OnInit, ViewChild} from '@angular/core';
import {Validable} from '../../services/validable';
import {LocationInputFieldComponent} from '../location-input-field/location-input-field.component';
import {GoogleCampaign} from '../../models/google-campaign';
import {Location} from '../../models/location';

@Component({
  selector: 'tr-create-smart-campaign',
  templateUrl: './create-smart-campaign.component.html',
  styleUrls: ['./create-smart-campaign.component.css']
})
export class CreateSmartCampaignComponent implements OnInit, Validable {
  campaignName: string;
  dailyBudget: number;
  @ViewChild('locationControl', {static: false})
  locationControl: LocationInputFieldComponent;
  showLocationControl: boolean;
  selectedLocations: Location[];

  constructor() { }

  formatDateString(date: Date): string {
    return (date.getDate() < 10 ? '0' : '') + date.getDate()
            + '.' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1)
            + '.' + date.getFullYear();
  }

  ngOnInit() {
    this.campaignName = 'My smart campaign ' + this.formatDateString(new Date());
    this.selectedLocations = [];
    this.showLocationControl = true;
    this.dailyBudget = 10;
  }

  isValid(): boolean {
    return this.campaignName
            && this.campaignName.length > 0
            && this.dailyBudget
            && this.dailyBudget > 0
            && this.selectedLocations.length > 0;
  }

  getGoogleCampaign() {
    const campaign = new GoogleCampaign();
    campaign.name = this.campaignName.trim();
    campaign.dailyBudget = this.dailyBudget;
    campaign.locations = this.selectedLocations;
    return campaign;
  }

  onLocationSelected(selectedValue: Location) {
    this.selectedLocations[this.selectedLocations.length] = selectedValue;
    this.locationControl.clear();
    this.showLocationControl = false;
  }

  removeLocation(location: Location) {
    this.selectedLocations = this.selectedLocations.filter(l => l !== location);
    if (this.selectedLocations.length === 0) {
      this.showLocationControl = true;
    }
  }
}
