import {Component, NgZone, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication-service';
import {AlertServiceService} from '../services/alert-service.service';
import {GoogleAuthServiceService} from '../services/google-auth-service.service';
import {User} from '../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertServiceService,
    private signInService: GoogleAuthServiceService,
    private ngZone: NgZone
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const user = new User();
    user.email = this.f.username.value;
    user.password = this.f.password.value;
    this.authenticationService.login(user)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.router.navigate([this.returnUrl], { replaceUrl: true });
          // window.location.reload();
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

  googleLoginCallback() {
    return (user: User) => {
      this.ngZone.run(() => {
        console.log('Login callback: ' + JSON.stringify(user));
        this.loading = true;
        this.authenticationService.login(user)
          .pipe(first())
          .subscribe(
            data => {
              this.loading = false;
              this.router.navigate([this.returnUrl], { replaceUrl: true });
              // window.location.reload();
            },
            error => {
              this.alertService.error(error);
              this.loading = false;
            });
      });
    };
  }
}
