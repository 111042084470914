import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
import {Advert} from '../models/advert';
import {DatafeedComponent} from '../datafeed/datafeed.component';
import {DatafeedConfiguration} from '../models/datafeed/datafeed-configuration';
import {GetDatafeedProductsRequest} from '../models/get-datafeed-products-request';
import {DatafeedProduct} from '../models/datafeed-product';
import {Datafeed} from '../models/datafeed/datafeed';
import {DatafeedAnalyzationResult} from '../models/datafeed/datafeed-analyzation-result';
import {DatafeedAnalyzationRequest} from '../models/datafeed/datafeed-analyzation-request';
import {SaveDatafeedConfigurationRequest} from '../models/datafeed/save-datafeed-configuration-request';

@Injectable({
  providedIn: 'root'
})
export class ProductDatafeedService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  getDatafeedConfiguration(): Observable<DatafeedConfiguration> {
    return this.http.get<DatafeedConfiguration>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/datafeedConfiguration');
  }

  analyzeDatafeed(datafeedAnalyzationRequest: DatafeedAnalyzationRequest): Observable<DatafeedAnalyzationResult> {
    return this.http.post<DatafeedAnalyzationResult>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/datafeed/analyze', datafeedAnalyzationRequest);
  }

  hasDatafeedConfiguration(): Observable<boolean> {
    return this.http.get<boolean>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/hasDatafeedConfiguration');
  }

  getDatafeedProducts(request: GetDatafeedProductsRequest): Observable<DatafeedProduct[]> {
    return this.http.post<DatafeedProduct[]>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/datafeedProducts', request);
  }

  saveDatafeedConfiguration(saveDatafeedConfigurationRequest: SaveDatafeedConfigurationRequest): Observable<void> {
    return this.http.post<void>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/datafeedConfiguration', saveDatafeedConfigurationRequest);
  }

  deleteDatafeedConfiguration(): Observable<void> {
    return this.http.delete<void>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/datafeedConfiguration');
  }

  runDatafeed(): Observable<void> {
    return this.http.get<void>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/runDatafeedConfiguration');
  }
}
