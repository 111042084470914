import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
import {SearchLocation} from '../models/search-location';
import {Location} from '../models/location';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  search(searchLocation: SearchLocation): Observable<Location[]> {
    return this.http.post<Location[]>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/locations/search', searchLocation);
  }
}
