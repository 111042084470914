export class PagedParameterVariants {
  parameterVariants: Array<{}>;
  pageParameterVariants: Array<{}>;
  pageSize: number;
  pages: Array<number>;
  currentPage: number;
  DEFAULT_PAGE_SIZE: number;

  constructor() {
    this.DEFAULT_PAGE_SIZE = 4;
    this.parameterVariants = [];
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.currentPage = 1;
  }

  setParameterVariants(parameterVariants: Array<{}>) {
    this.parameterVariants = parameterVariants;
    this.pageParameterVariants = parameterVariants.slice(0, this.pageSize);

    this.pages = [];
    for (let page = 1; page <= Math.ceil((parameterVariants.length / this.pageSize)); page++) {
      this.pages[this.pages.length] = page;
    }
  }

  clear() {
    this.parameterVariants = [];
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.pages = [1];
    this.currentPage = 1;
  }

  setPage(page: number) {
    this.currentPage = page;
    this.pageParameterVariants = this.parameterVariants.slice((page - 1) * this.pageSize,  page * this.pageSize);
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.setPage(this.currentPage + 1);
    }
  }

  prevPage() {
    if(this.currentPage > 0) {
      this.setPage(this.currentPage - 1);
    }
  }
}
