import { Component, OnInit } from '@angular/core';
import {ProductDatafeedService} from '../services/product-datafeed.service';
import {DatafeedConfiguration} from '../models/datafeed/datafeed-configuration';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../_components/confirm-dialog/confirm-dialog.component';
import {AlertServiceService} from '../services/alert-service.service';

@Component({
  selector: 'tr-datafeed',
  templateUrl: './datafeed.component.html',
  styleUrls: ['./datafeed.component.css']
})
export class DatafeedComponent implements OnInit {

  constructor(private datafeedService: ProductDatafeedService,
              public dialog: MatDialog,
              private alertService: AlertServiceService
  ) { }

  datafeedConfiguration: DatafeedConfiguration;

  loading: boolean;

  loadingDatafeedHistory: boolean;

  ngOnInit() {
    this.loadDatafeedConfiguration();
  }

  loadDatafeedConfiguration(): void {
    this.loading = true;
    this.datafeedService.getDatafeedConfiguration().subscribe((datafeedConfiguration: DatafeedConfiguration) => {
      this.loading = false;
      this.datafeedConfiguration = datafeedConfiguration;
    }, (error) => {
      this.loading = false;
    });
  }

  reloadDatafeedHistory(): void {
    this.loadingDatafeedHistory = true;
    this.datafeedService.getDatafeedConfiguration().subscribe((datafeedConfiguration: DatafeedConfiguration) => {
      this.loadingDatafeedHistory = false;
      this.datafeedConfiguration = datafeedConfiguration;
    }, (error) => {
      this.loadingDatafeedHistory = false;
    });
  }

  deleteDatafeedConfiguration(): void {
    this.alertService.clear();
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete datafeed configuration',
        message: 'Are you sure you want to delete datafeed configuration?',
        confirmButton: 'Delete',
        cancelButton: 'Cancel',
        onConfirm: () => {
          this.datafeedService.deleteDatafeedConfiguration().subscribe(() => {
            this.loadDatafeedConfiguration();
          }, error => {
            this.alertService.error('Failed to delete configuration, error: ' + error);
          });
        }
      }
    });
  }

  runNowDatafeed(): void {
    this.alertService.clear();
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Run datafeed',
        message: 'Are you sure you want to run datafeed?',
        confirmButton: 'Run',
        cancelButton: 'Cancel',
        onConfirm: () => {
          this.datafeedService.runDatafeed().subscribe(() => {
            this.loadDatafeedConfiguration();
          }, error => {
            this.alertService.error('Failed to run datafeed, error: ' + error);
          });
        }
      }
    });
  }
}
