import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {User} from './models/user';
import {Router} from '@angular/router';
import {AuthenticationService} from './services/authentication-service';
import * as $ from 'jquery';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Account} from './models/account';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser: User;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  selectAccount(account: Account) {
    this.authenticationService.selectAccount(account);
    // this.router.navigate(['/']);
    window.location.reload();
  }

  anchorClicked(event: MouseEvent) {
    let targetElement = event.target as HTMLElement;
    if (!targetElement.id && targetElement.parentElement) {
      targetElement = targetElement.parentElement;
    }
    const target = targetElement.id;

    const $li = $('#' + target.replace('chevron', 'li')).parent();

    if ($li.is('.active')) {
      $li.removeClass('active active-sm');
      $('ul:first', $li).slideUp(() => {});
    } else {
      // prevent closing menu if we are on child menu
      if (!$li.parent().is('.child_menu')) {
        $('#sidebar-menu')
          .find('li')
          .removeClass('active active-sm');
        $('#sidebar-menu')
          .find('li ul')
          .slideUp();
      }

      $li.addClass('active');

      $('ul:first', $li).slideDown(() => {});
    }
  }

  toggleClicked(event: MouseEvent) {
    const body = $('body');
    const menu = $('#sidebar-menu');

    // toggle small or large menu
    if (body.hasClass('nav-md')) {
      menu.find('li.active ul').hide();
      menu
        .find('li.active')
        .addClass('active-sm')
        .removeClass('active');
    } else {
      menu.find('li.active-sm ul').show();
      menu
        .find('li.active-sm')
        .addClass('active')
        .removeClass('active-sm');
    }
    body.toggleClass('nav-md nav-sm');
  }

  fixMinHeight(): void {
    const h = $(window).height();
    if (h && h > 0) {
      $('.right_col').css('min-height', h + 'px');
    }
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(200)).subscribe( evt => {
      this.fixMinHeight();
    });
    this.router.events.pipe(debounceTime(200)).subscribe((event) => {
      this.fixMinHeight();
    });

    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
}
