import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Template} from '../models/template';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(this.config.serverUrl + '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/templates');
  }

  getTemplate(id: number): Observable<Template> {
    return this.http.get<Template>(this.config.serverUrl + '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/templates/' + id);
  }
}
