import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Advert} from '../models/advert';
import {HttpClient} from '@angular/common/http';
import {CreateAdvertRequest} from '../models/create-advert-request';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';
import {CreateDatafeedAdvertsRequest} from '../models/create-datafeed-adverts-request';
import {UpdateAdvertRequest} from '../models/update-advert-request';
import {UpdateAdvertsTemplateRequest} from '../models/update-adverts-template-request';

@Injectable({
  providedIn: 'root'
})
export class AdvertsService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  getAdverts(): Observable<Advert[]> {
    return this.http.get<Advert[]>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts');
  }

  getAdvertMoreData(advert: Advert) {
    return this.http.get<Advert>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts/' + advert.id);
  }

  getAdvert(advert: Advert) {
    return this.getAdvertById(advert.id);
  }

  getAdvertById(advertId: number) {
    return this.http.get<Advert>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts/' + advertId);
  }

  getAdvertsTemplate(advertIds: number[]) {
    return this.http.get<Advert[]>(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/advertsTemplate/' + advertIds.join(','));
  }

  updateAdvertsTemplate(updateAdvertRequest: UpdateAdvertsTemplateRequest) {
    return this.http.post(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/advertsTemplate/' + updateAdvertRequest.adverts.map(a => a.id).join(','), updateAdvertRequest);
  }

  addAdvert(createAdvertRequest: CreateAdvertRequest) {
    return this.http.post(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/createAdvert', createAdvertRequest);
  }

  updateAdvert(updateAdvertRequest: UpdateAdvertRequest) {
    return this.http.post(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/updateAdvert', updateAdvertRequest);
  }

  addDatafeedAdverts(createAdvertRequest: CreateDatafeedAdvertsRequest) {
    return this.http.post(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/createDatafeedAdverts', createAdvertRequest);
  }

  deleteAdvert(advert: Advert): Observable<any> {
    return this.http.delete(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts/' + advert.id);
  }

  resetAdvertLearnings(advert: Advert): Observable<any> {
    return this.http.get(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts/' + advert.id + '/learning/resetAdvertLearning');
  }

  enableAdvert(advert: Advert): Observable<any> {
    return this.http.post(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts/' + advert.id + '/enable', advert);
  }

  pauseAdvert(advert: Advert): Observable<any> {
    return this.http.post(this.config.serverUrl +  '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/adverts/' + advert.id + '/pause', advert);
  }
}
