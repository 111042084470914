import {DatafeedDataType} from './datafeed-data-type.enum';

export class Datafeed {
  id: number;
  feedUrl: string;
  loading: boolean;
  saving: boolean;
  dataType: DatafeedDataType;
  charset: string;
  delimiter: string;
  productTag: string;
}
