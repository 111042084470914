import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AdsComponent } from './ads/ads.component';
import { SettingsComponent } from './settings/settings.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {fakeBackendProvider} from './helpers/fake-backend-interceptor';
import {JwtInterceptor} from './helpers/jwt-interceptor';
import {ErrorInterceptor} from './helpers/error-interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NoAdsListComponent } from './ads/no-ads-list/no-ads-list.component';
import { AddAdComponent } from './add-ad/add-ad.component';
import { DynamicPlayerComponent } from './_components/dynamic-player/dynamic-player.component';
import { AlertComponent } from './_components/alert/alert.component';
import { AdsListComponent } from './ads/ads-list/ads-list.component';
import { GoogleLoginComponent } from './_components/google-login/google-login.component';
import { ImagesInputFieldComponent } from './_components/images-input-field/images-input-field.component';
import { TextsInputFieldComponent } from './_components/texts-input-field/texts-input-field.component';
import { GoogleTargetingSelectionComponent } from './_components/google-targeting-selection/google-targeting-selection.component';
import { DynamicPlayerIframeComponent } from './_components/dynamic-player-iframe/dynamic-player-iframe.component';
import { TrpdModalComponent } from './_components/trpd-modal/trpd-modal.component';
import { LocationInputFieldComponent } from './_components/location-input-field/location-input-field.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CreateSmartCampaignComponent } from './_components/create-smart-campaign/create-smart-campaign.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DatafeedComponent } from './datafeed/datafeed.component';
import { DatafeedConfigurationComponent } from './datafeed-configuration/datafeed-configuration.component';
import { AddDatafeedAdsComponent } from './add-datafeed-ads/add-datafeed-ads.component';
import { DatafeedProductsComponent } from './_components/datafeed-products/datafeed-products.component';
import {MatSortModule} from '@angular/material/sort';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatSelectModule} from '@angular/material/select';
import { VideosInputFieldComponent } from './_components/videos-input-field/videos-input-field.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MessageDialogComponent } from './_components/message-dialog/message-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { ConfirmDialogComponent } from './_components/confirm-dialog/confirm-dialog.component';
import { EditAdComponent } from './edit-ad/edit-ad.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AdsComponent,
    SettingsComponent,
    NoAdsListComponent,
    AddAdComponent,
    DynamicPlayerComponent,
    AlertComponent,
    AdsListComponent,
    GoogleLoginComponent,
    ImagesInputFieldComponent,
    TextsInputFieldComponent,
    GoogleTargetingSelectionComponent,
    DynamicPlayerIframeComponent,
    TrpdModalComponent,
    LocationInputFieldComponent,
    CreateSmartCampaignComponent,
    DatafeedComponent,
    DatafeedConfigurationComponent,
    AddDatafeedAdsComponent,
    DatafeedProductsComponent,
    VideosInputFieldComponent,
    MessageDialogComponent,
    ConfirmDialogComponent,
    EditAdComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatProgressSpinnerModule,
        NgxPaginationModule,
        MatSortModule,
        NgbModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    , { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    // provider used to create fake backend
    // , fakeBackendProvider
  ],
  entryComponents: [MessageDialogComponent, ConfirmDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
