import {Location} from './location';

export class GoogleCampaign {
  id: number;
  name: string;
  googleClientId: number;
  trpdId: number;
  locations: Location[];
  dailyBudget: number;
  status: number;
}
