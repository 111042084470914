import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmDialogProgress {
  progress: number;
  confirmCompleted: any;
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  onConfirm: any;
  onDecline: any;
  onCancel: any;
  confirmButton: string;
  declineButton: string;
  cancelButton: string;
  confirmWithProgress: boolean;
}

@Component({
  selector: 'tr-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  onConfirm: any;
  onDecline: any;
  onCancel: any;
  confirmButton: string;
  declineButton: string;
  cancelButton: string;
  inProgress: boolean;
  confirmDialogProgress: ConfirmDialogProgress;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    this.title = data.title;
    this.message = data.message;
    this.onConfirm = data.onConfirm;
    this.onCancel = data.onCancel;
    this.onDecline = data.onDecline;
    this.confirmButton = data.confirmButton || 'Confirm';
    this.declineButton = data.declineButton;
    this.cancelButton = data.cancelButton || 'Close';
    if(data.confirmWithProgress){
      this.confirmDialogProgress = {
        confirmCompleted : () => {
          this.inProgress = false;
          this.close();
        },
        progress: 0
      };
    }
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

  confirm() {
    if (this.onConfirm && this.confirmDialogProgress) {
      this.inProgress = true;
      try {
        this.onConfirm(this.confirmDialogProgress);
      } catch (e) {
        this.inProgress = false;
      }
    } else if(this.onConfirm){
        this.onConfirm();
        this.dialogRef.close();
      }
  }

  decline() {
    if (this.onDecline) {
      this.onDecline();
    }
    this.dialogRef.close();
  }

  cancel() {
    if (this.onCancel) {
      this.onCancel();
    }
    this.dialogRef.close();
  }
}
