import {GoogleUser} from './google-user';
import {Account} from './account';

export class User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  name: string;
  imageUrl: string;
  code: string;
  google: GoogleUser;
  account: string;
  accountName: string;
  accounts: Account[];
}
