import { Component, OnInit } from '@angular/core';
import {Advert} from '../../models/advert';
import {ProductDatafeedService} from '../../services/product-datafeed.service';

@Component({
  selector: 'tr-no-ads-list',
  templateUrl: './no-ads-list.component.html',
  styleUrls: ['./no-ads-list.component.css']
})
export class NoAdsListComponent implements OnInit {
  hasDatafeedConfiguration: boolean;

  constructor(private datafeedService: ProductDatafeedService) { }

  ngOnInit() {
    this.datafeedService.hasDatafeedConfiguration().subscribe((hasDatafeedConfiguration) => {
      this.hasDatafeedConfiguration = hasDatafeedConfiguration;
    });
  }
}
