import {Component, OnInit} from '@angular/core';
import {ProductDatafeedService} from '../services/product-datafeed.service';
import {Datafeed} from '../models/datafeed/datafeed';
import {DatafeedAnalyzationResult} from '../models/datafeed/datafeed-analyzation-result';
import {FormControl} from '@angular/forms';
import {DatafeedConfiguration} from '../models/datafeed/datafeed-configuration';
import {DatafeedAnalyzationRequest} from '../models/datafeed/datafeed-analyzation-request';
import {SaveDatafeedConfigurationRequest} from '../models/datafeed/save-datafeed-configuration-request';
import {AlertServiceService} from '../services/alert-service.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialogComponent} from '../_components/message-dialog/message-dialog.component';

export class EditDatafeedConfiguration {
  datafeedAnalyzationResult: DatafeedAnalyzationResult;
  datafeedAnalyzationForm = new FormControl();
}

@Component({
  selector: 'tr-datafeed-configuration',
  templateUrl: './datafeed-configuration.component.html',
  styleUrls: ['./datafeed-configuration.component.css']
})
export class DatafeedConfigurationComponent implements OnInit {

  constructor(private datafeedService: ProductDatafeedService,
              private alertService: AlertServiceService,
              private router: Router,
              public dialog: MatDialog,
              ) { }

  datafeedConfiguration: DatafeedConfiguration;

  editDatafeed: Map<Datafeed, DatafeedAnalyzationResult>;

  loading: boolean;

  ngOnInit() {
    this.loadDatafeedConfiguration();
    this.editDatafeed = new Map<Datafeed, DatafeedAnalyzationResult>();
  }

  loadDatafeedConfiguration(): void {
    this.loading = true;
    this.datafeedService.getDatafeedConfiguration().subscribe((datafeedConfiguration: DatafeedConfiguration) => {
      this.loading = false;
      if (datafeedConfiguration && datafeedConfiguration.datafeeds && datafeedConfiguration.datafeeds.length > 0) {
        this.datafeedConfiguration = datafeedConfiguration;
      } else {
        this.datafeedConfiguration = new DatafeedConfiguration();
        this.datafeedConfiguration.datafeeds = [];
        this.datafeedConfiguration.datafeeds[0] = new Datafeed();
        this.datafeedConfiguration.datafeeds[0].charset = 'UTF-8';
      }
    }, (error) => {
      this.loading = false;
    });
  }

  config(datafeed: Datafeed): void {
    this.alertService.clear();
    // TODO: analyze and load datafeed
    const datafeedAnalyzationRequest = new DatafeedAnalyzationRequest();
    datafeedAnalyzationRequest.datafeed = datafeed;
    datafeed.loading = true;
    this.datafeedService.analyzeDatafeed(datafeedAnalyzationRequest).subscribe((datafeedAnalyzationResult: DatafeedAnalyzationResult) => {
      datafeed.loading = false;
      if (datafeedAnalyzationResult.error !== undefined) {
        this.alertService.error('Datafeed is not compatible. Please contact the service team for help.');
      } else {
        datafeed.charset = datafeedAnalyzationResult.datafeed.charset;
        datafeed.dataType = datafeedAnalyzationResult.datafeed.dataType;
        datafeed.delimiter = datafeedAnalyzationResult.datafeed.delimiter;
        datafeed.productTag = datafeedAnalyzationResult.datafeed.productTag;
        const datafeedConfiguration = new EditDatafeedConfiguration();
        datafeedConfiguration.datafeedAnalyzationForm = new FormControl();
        datafeedConfiguration.datafeedAnalyzationResult = datafeedAnalyzationResult;
        this.editDatafeed.set(datafeed, datafeedAnalyzationResult);
      }
    }, error => {
      this.alertService.error('Failed to configure, error: ' + error);
      datafeed.loading = false;
    });
  }

  cancelEditing(datafeed: Datafeed) {
    this.editDatafeed.delete(datafeed);
  }

  validate(datafeed: Datafeed): boolean {
    let skuConfigured = false;
    const SKU_FIELD_ID = 1;
    const datafeedAnalyzationResult = this.editDatafeed.get(datafeed);
    for (const datafeedElementConfiguration of datafeedAnalyzationResult.datafeedElementConfigurations) {
      if (datafeedElementConfiguration.datafeedProductFieldIds && datafeedElementConfiguration.datafeedProductFieldIds.indexOf(SKU_FIELD_ID) !== -1) {
        skuConfigured = true;
      }
    }

    if (!skuConfigured) {
      this.alertService.error('Configuration Error. Please select mandatory SKU source');
      this.dialog.open(MessageDialogComponent, {
        data: {
          title : 'Configuration Error',
          message: 'Please select mandatory SKU source'
        }
      });
    }

    return skuConfigured;
  }

  save(datafeed: Datafeed) {
    this.alertService.clear();
    if (this.validate(datafeed)) {
      const datafeedAnalyzationResult = this.editDatafeed.get(datafeed);

      const saveDatafeedConfigurationRequest = new SaveDatafeedConfigurationRequest();
      saveDatafeedConfigurationRequest.datafeed = datafeed;
      saveDatafeedConfigurationRequest.datafeedElementConfigurations = datafeedAnalyzationResult.datafeedElementConfigurations;
      saveDatafeedConfigurationRequest.probeId = datafeedAnalyzationResult.probeId;

      datafeed.saving = true;
      this.datafeedService.saveDatafeedConfiguration(saveDatafeedConfigurationRequest).subscribe(() => {
        datafeed.saving = false;
        this.editDatafeed.delete(datafeed);
        this.router.navigate(['/datafeed']);
      }, error => {
        this.alertService.error('Failed to save, error: ' + error);
        datafeed.saving = false;
      });
    }
  }
}
