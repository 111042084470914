import { Component, OnInit } from '@angular/core';
import {AdvertsService} from '../services/adverts.service';
import {Router} from '@angular/router';
import {AlertServiceService} from '../services/alert-service.service';
import {Advert} from '../models/advert';
import {AdsManager} from './ads-manager';
import {ConfirmDialogComponent, ConfirmDialogProgress} from '../_components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit, AdsManager {
  adverts: Advert [];
  loading: boolean;

  constructor(
    private advertsService: AdvertsService,
    private router: Router,
    private alertService: AlertServiceService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.adverts = null;
    this.loadAdverts();
  }

  deleteAdvert(advert: Advert): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to delete advert?',
        confirmButton: 'Delete',
        cancelButton: 'Cancel',
        onConfirm: () => {
          this.advertsService.deleteAdvert(advert).subscribe(() => {
            this.loadAdverts();
          }, (error) => {
            this.alertService.error(error);
          });
        }
      }
    });
  };

  deleteAdverts(adverts: Advert[]): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to delete ' + adverts.length  + (adverts.length > 1 ? ' adverts' : ' advert') + '?',
        confirmButton: 'Delete',
        cancelButton: 'Cancel',
        onConfirm: () => {
          let waitAdverts = 0;
          const errors = [];
          for(const advert of adverts) {
            waitAdverts++;
            this.advertsService.deleteAdvert(advert).subscribe(() => {
              waitAdverts--;
              if (waitAdverts === 0) {
                this.loadAdverts();
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            }, (error) => {
              waitAdverts--;
              errors.push(error);
              if (waitAdverts === 0) {
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            });
          }
        }
      }
    });
  };

  resetAdvertLearnings(adverts: Advert[]): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to reset ' + adverts.length  + (adverts.length > 1 ? ' adverts learning data' : ' advert learning data') + '?',
        confirmButton: 'Reset',
        cancelButton: 'Cancel',
        confirmWithProgress: true,
        onConfirm: (confirmDialogProgress: ConfirmDialogProgress) => {
          const errors = [];
          let totalAdverts = adverts.length;
          let completedAdverts = 0;
          for(const advert of adverts) {
            this.advertsService.resetAdvertLearnings(advert).subscribe(() => {
              completedAdverts++;
              confirmDialogProgress.progress = 100 * completedAdverts / totalAdverts;
              if (completedAdverts === totalAdverts) {
                confirmDialogProgress.confirmCompleted();
                this.loadAdverts();
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            }, (error) => {
              completedAdverts++;
              confirmDialogProgress.progress = 100 * completedAdverts / totalAdverts;
              errors.push(error);
              if (completedAdverts === totalAdverts) {
                confirmDialogProgress.confirmCompleted();
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            });
          }
        }
      }
    });
  };

  enableAdvert(advert: Advert): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to enable advert?',
        confirmButton: 'Enable',
        cancelButton: 'Cancel',
        onConfirm: () => {
          this.advertsService.enableAdvert(advert).subscribe(() => {
            this.loadAdverts();
          }, (error) => {
            this.alertService.error(error);
          });
        }
      }
    });
  };

  pauseAdverts(adverts: Advert[]): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to pause ' + adverts.length  + (adverts.length > 1 ? ' adverts' : ' advert') + '?',
        confirmButton: 'Pause',
        cancelButton: 'Cancel',
        onConfirm: () => {
          let waitAdverts = 0;
          const errors = [];
          for(const advert of adverts) {
            waitAdverts++;
            this.advertsService.pauseAdvert(advert).subscribe(() => {
              waitAdverts--;
              if (waitAdverts === 0) {
                this.loadAdverts();
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            }, (error) => {
              waitAdverts--;
              errors.push(error);
              if (waitAdverts === 0) {
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            });
          }
        }
      }
    });
  };

  enableAdverts(adverts: Advert[]): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to enable '+ adverts.length  + (adverts.length > 1 ? ' adverts' : ' advert') + '?',
        confirmButton: 'Enable',
        cancelButton: 'Cancel',
        onConfirm: () => {
          let waitAdverts = 0;
          const errors = [];
          for(const advert of adverts) {
            waitAdverts++;
            this.advertsService.enableAdvert(advert).subscribe(() => {
              waitAdverts--;
              if (waitAdverts === 0) {
                this.loadAdverts();
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            }, (error) => {
              waitAdverts--;
              errors.push(error);
              if (waitAdverts === 0) {
                if (errors.length > 0) {
                  this.alertService.error(errors[0]);
                }
              }
            });
          }
        }
      }
    });
  };

  pauseAdvert(advert: Advert): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Adverts',
        message: 'Are you sure you want to pause advert?',
        confirmButton: 'Pause',
        cancelButton: 'Cancel',
        onConfirm: () => {
          this.advertsService.pauseAdvert(advert).subscribe(() => {
            this.loadAdverts();
          }, (error) => {
            this.alertService.error(error);
          });
        }
      }
    });
  };

  loadAdverts() {
    this.loading = true;
    this.advertsService.getAdverts().subscribe((adverts: Advert[]) => {
      this.adverts = adverts;
      this.loading = false;
    });
  }
}
