import {Component, Input, OnInit} from '@angular/core';
import {GoogleAuthServiceService} from '../../services/google-auth-service.service';
import {GoogleUser} from '../../models/google-user';
import {User} from '../../models/user';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.css']
})
export class GoogleLoginComponent implements OnInit {
  buttonReady: boolean;
  auth2: any;
  @Input()
  offline: boolean;
  @Input()
  disabled: boolean;
  @Input()
  callback: Function;
  @Input()
  buttonText: string;

  loading: boolean;

  constructor(private signInService: GoogleAuthServiceService) {
    this.buttonReady = true;
  }

  signIn() {
    if (this.offline) {
      this.loading = true;
      this.signInService.signInOffline((user: User) => {
        this.loading = false;
        if (this.callback) {
          this.callback(user);
        }
      });
    } else {
      this.loading = true;
      this.signInService.signIn((user: User) => {
        this.loading = false;
        if (this.callback) {
          this.callback(user);
        }
      });
    }
  }

  ngOnInit() {

  }
}
