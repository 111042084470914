import { Injectable } from '@angular/core';
import {Campaign} from '../models/campaign';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GoogleCampaign} from '../models/google-campaign';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  getAll(): Observable<Campaign[]> {
    return this.http.get<Campaign[]>(this.config.serverUrl + '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/campaigns');
  }

  getGoogleCampaigns(): Observable<GoogleCampaign[]> {
    return this.http.get<GoogleCampaign[]>(this.config.serverUrl + '/api/accounts/' + this.authenticationService.currentUserValue.account +  '/googleCampaigns');
  }
}
