import {Component, Input, OnInit} from '@angular/core';
import {ProductDatafeedService} from '../../services/product-datafeed.service';
import {DatafeedProduct} from '../../models/datafeed-product';
import {GetDatafeedProductsRequest} from '../../models/get-datafeed-products-request';

@Component({
  selector: 'tr-datafeed-products',
  templateUrl: './datafeed-products.component.html',
  styleUrls: ['./datafeed-products.component.css']
})
export class DatafeedProductsComponent implements OnInit {
  datafeedProducts: DatafeedProduct[];
  getDatafeedProductsRequest: GetDatafeedProductsRequest;
  loading: boolean;
  @Input()
  mandatory: boolean;
  valid: boolean;

  constructor(private datafeedService: ProductDatafeedService) {
    this.valid = true;
    this.getDatafeedProductsRequest = new GetDatafeedProductsRequest();
    this.getDatafeedProductsRequest.lastDays = 1;
    this.getDatafeedProductsRequest.limit = 30;
    this.getDatafeedProductsRequest.top = false;
    this.searchDatafeedProducts();
  }

  getSelectedDatafeedProducts(): DatafeedProduct[] {
    return this.datafeedProducts.filter((datafeedProduct) => {
      return datafeedProduct.selected;
    });
  }

  searchDatafeedProducts(): void {
    this.loading = true;
    this.datafeedService.getDatafeedProducts(this.getDatafeedProductsRequest).subscribe((datafeedProducts: DatafeedProduct[]) => {
      this.loading = false;
      this.datafeedProducts = datafeedProducts.filter(product => {
        return product.sku && product.sku !== 'demo';
      });
    }, error => {
      this.loading = false;
      this.datafeedProducts = [];
      throw new Error('Something went wrong. Please try again later.');
    });
  }

  ngOnInit() {

  }

  validate(): boolean {
    if (this.mandatory) {
      if (this.datafeedProducts) {
        for (const product of this.datafeedProducts) {
          if (product.selected) {
            this.valid = true;
            return true;
          }
        }
      }
      this.valid = false;
      return false;
    } else {
      this.valid = true;
      return true;
    }
  }

  onProductSelected($event: MouseEvent, product: DatafeedProduct) {
    if (!product.selected) {
      this.valid = true;
    }
  }

  selectAll(): void {
    for (const product of this.datafeedProducts) {
      product.selected = true;
    }
  }

  deselectAll(): void {
    for (const product of this.datafeedProducts) {
      product.selected = false;
    }
  }

  toggleSelectAll($event: any) {
    this.valid = true;
    if ($event.target.checked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }
}
