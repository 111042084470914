import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CampaignsService} from '../services/campaigns.service';
import {AdGroupsService} from '../services/ad-groups.service';
import {TemplateService} from '../services/template.service';
import {AdvertsService} from '../services/adverts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertServiceService} from '../services/alert-service.service';
import {AuthenticationService} from '../services/authentication-service';
import {ConfigService} from '../services/config.service';
import {TrpdModalServiceService} from '../services/trpd-modal-service.service';
import {Advert} from '../models/advert';
import {TemplateParameterValue} from '../models/template-parameter-value';
import {Template} from '../models/template';
import {TemplateParameter} from '../models/template-parameter';
import {PagedParameterVariants} from '../models/paged-parameter-variants';
import {DatafeedProduct} from '../models/datafeed-product';
import {CreateDatafeedAdvertsRequest} from '../models/create-datafeed-adverts-request';
import {UpdateAdvertRequest} from '../models/update-advert-request';
import {UpdateAdvertsTemplateRequest} from '../models/update-adverts-template-request';

@Component({
  selector: 'tr-edit-ad',
  templateUrl: './edit-ad.component.html',
  styleUrls: ['./edit-ad.component.css']
})
export class EditAdComponent implements OnInit {
  loading: boolean;
  saving: boolean;
  tagsSplitter: string;
  adverts: Advert[];
  selectedTemplate: Template;
  templateParametersForm: FormGroup;
  selectedTemplatePagedParameterVariants: PagedParameterVariants;
  previewVariantsPage: number;
  forcePreviewVersion: number;
  selectedTemplateParameters: {};
  previewAdsPage: number;
  hasDatafeedProducts: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private campaignsService: CampaignsService,
    private adGroupService: AdGroupsService,
    private templatesService: TemplateService,
    private advertsService: AdvertsService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertServiceService,
    private authenticationService: AuthenticationService,
    private config: ConfigService,
    private modalService: TrpdModalServiceService
  ) {
    this.tagsSplitter = ',';
    this.selectedTemplatePagedParameterVariants = new PagedParameterVariants();
    this.selectedTemplateParameters = {};
    this.adverts = [];
  }

  getCurrentUser() {
    return this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('advertId')) {
        // tslint:disable-next-line:radix
        this.loadAdvert(params.get('advertId').split(',').map(value => Number.parseInt(value)));
      }
    });
    this.templateParametersForm = this.formBuilder.group({});
  }

  private loadAdvert(advertIds: number[]) {
    this.loading = true;
    this.advertsService.getAdvertsTemplate(advertIds).subscribe(adverts => {
      this.adverts = adverts;
      for (const advert of adverts) {
        this.hasDatafeedProducts = this.hasDatafeedProducts || advert.product !== undefined;
        advert.product = advert.product || new DatafeedProduct();
      }
      this.loading = false;
      this.loadSelectedTemplate();
    });
  }

  addValue(parameter: TemplateParameter, value: string) {
    if (!parameter.value) {
      parameter.value = new TemplateParameterValue();
      parameter.value.parameterId = parameter.id;
      parameter.value.value = parameter.defaultValue;
    }
    parameter.value.value =  (parameter.value.value || '') + (parameter.value.value ? ',' : '') + value;
  }

  onParameterChanged(parameter: TemplateParameter, value: string) {
    console.log('Parameter: ' + parameter.name + ', value: ' + value);
    if (!parameter.value) {
      parameter.value = new TemplateParameterValue();
      parameter.value.parameterId = parameter.id;
    }
    parameter.value.value = value;
  }

  loadSelectedTemplate(): boolean {
    const loadedTemplateCallback = (template) => {
      this.selectedTemplate = template;

      const paramId2param = {};
      for (const templateParameter of this.adverts[0].templateParameters) {
        paramId2param[templateParameter.id] = templateParameter;
      }

      const controlsConfig = {};
      for (const param of this.selectedTemplate.parameters) {
        controlsConfig[param.name] = ['', Validators.required];

        if (paramId2param[param.id]) {
          param.value = new TemplateParameterValue();
          param.value.parameterId = param.id;

          const templateParameter: TemplateParameter = paramId2param[param.id];
          param.value.value = templateParameter.value.value;
        }
      }
      this.templateParametersForm = this.formBuilder.group(controlsConfig);

      // this.previewAd();
    };

    if (this.adverts[0] && this.adverts[0].template) {
      this.loading = true;
      this.templatesService.getTemplate(this.adverts[0].template.id).pipe().subscribe(template => {
        this.loading = false;
        loadedTemplateCallback(template);
      }, (e) => {
        this.loading = false;
        this.alertService.error(e, false);
      });
      return true;
    }
    return false;
  }

  viewMoreAd(modalId, product: DatafeedProduct) {
    this.previewVariantsPage = 1;
    this.selectedTemplatePagedParameterVariants.clear();
    this.modalService.open(modalId);

    if (this.selectedTemplate) {
      this.selectedTemplatePagedParameterVariants.setParameterVariants(
        this.generateParametersCombinations(this.selectedTemplate.parameters, product));
    }
  }

  closeModal(modalId) {
    this.selectedTemplatePagedParameterVariants.clear();
    this.modalService.close(modalId);
  }

  getTemplateParameters(product: DatafeedProduct): any {
    if (this.selectedTemplate) {
      const selectedTemplateParameters = {};
      product = product || this.adverts[0].product;
      for (const parameter of this.selectedTemplate.parameters) {
        if (parameter.type === 'text'
          || parameter.type === 'image'
          || parameter.type === 'video'
          || parameter.type === 'checkbox'
          || parameter.type === 'select') {
          const strValue = parameter.value && parameter.value.value;
          const splittedRawValues = strValue ? strValue.split(this.tagsSplitter) : [undefined];
          const splittedValues = this.applyProductParameters(product, splittedRawValues);
          if (parameter.display) {
            selectedTemplateParameters[parameter.name] = splittedValues[splittedValues.length - 1];
          } else {
            selectedTemplateParameters[parameter.name] = splittedValues[0];
          }
        }
      }
      const versionParameterName = '__version__';
      selectedTemplateParameters[versionParameterName] = this.forcePreviewVersion;
      return selectedTemplateParameters;
    }
  }

  generateParametersCombinations(templateParameters: TemplateParameter[], product: DatafeedProduct) {
    const result = [];
    const parameters = [];
    product = product || this.adverts[0].product;

    const sortedTemplateParameters: TemplateParameter[] = templateParameters.slice().sort((a: TemplateParameter, b: TemplateParameter) => {
      return (b.optimizationOrder || 0) - (a.optimizationOrder || 0);
    });

    for (const parameter of sortedTemplateParameters) {
      const parameterValues = [];
      if (parameter.type === 'text'
        || parameter.type === 'image'
        || parameter.type === 'video'
        || parameter.type === 'checkbox'
        || parameter.type === 'select') {
        const strValue = parameter.value && parameter.value.value;
        const splittedRawValues = strValue ? strValue.split(this.tagsSplitter) : [undefined];
        const splittedValues = this.applyProductParameters(product, splittedRawValues);
        for (const splittedValue of splittedValues) {
          parameterValues[parameterValues.length] = {
            name: parameter.name,
            value: splittedValue
          };
        }
        parameters[parameters.length] = parameterValues;
      }
    }
    const parametersCombinations = parameters.reduce((a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w))), []));
    for (const parametersCombination of parametersCombinations) {
      const combinationObject = {};
      for (const combinationValue of parametersCombination) {
        combinationObject[combinationValue.name] = combinationValue.value;
      }
      result[result.length] = combinationObject;
    }
    return result;
  }

  applyProductParameters(product: DatafeedProduct, rawValues: string[]) {
    const resultValues = [];
    for (const rawValue of rawValues) {
      if (rawValue === '{product.name}') {
        resultValues.push(product.name);
      } else
      if (rawValue === '{product.category}') {
        resultValues.push(product.category);
      } else
      if (rawValue === '{product.image.url}') {
        if (product.images && product.images.length > 0) {
          for (const image of product.images) {
            resultValues.push(image);
          }
        }
      } else {
        resultValues.push(rawValue);
      }
    }
    return resultValues;
  }

  previewAd() {
    if (this.selectedTemplate) {
      const product = this.adverts[0].product;
      for (const parameter of this.selectedTemplate.parameters) {
        if (parameter.type === 'text'
          || parameter.type === 'image'
          || parameter.type === 'video'
          || parameter.type === 'checkbox'
          || parameter.type === 'select') {
          const strValue = parameter.value && parameter.value.value;
          const splittedRawValues = strValue ? strValue.split(this.tagsSplitter) : [undefined];
          const splittedValues = this.applyProductParameters(product, splittedRawValues);
          this.selectedTemplateParameters[parameter.name] = splittedValues[splittedValues.length - 1];
        }
      }
      // if (this.videoPlayer) {
      //   this.videoPlayer.update();
      // }
      this.forcePreviewVersion++;
    }
  }

  onCancel() {
    this.router.navigate(['/']);
  }

  submitAdvert() {
    const request = new UpdateAdvertsTemplateRequest();
    request.adverts = this.adverts;
    request.parameters = this.selectedTemplate.parameters;

    this.saving = true;
    this.advertsService
      .updateAdvertsTemplate(request)
      .subscribe(() => {
        this.saving = false;
        this.router.navigate(['/']);
      }, error => {
        this.saving = false;
        this.alertService.error('Failed to update Advert. Please try again later.', false);
        console.log(error);
      }, () => {
    });
  }
}
