import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Campaign} from '../models/campaign';
import {AdGroup} from '../models/ad-group';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication-service';
import {ConfigService} from './config.service';
import {GoogleAdGroup} from '../models/google-ad-group';

@Injectable({
  providedIn: 'root'
})
export class AdGroupsService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private config: ConfigService) { }

  getGoogleAdGroups(campaign: Campaign): Observable<AdGroup[]> {
    return this.http.get<GoogleAdGroup[]>(this.config.serverUrl + '/api/accounts/' + this.authenticationService.currentUserValue.account + '/googleCampaigns/' + campaign.id + '/googleAdGroups');
  }
}
