import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Template} from '../../models/template';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import * as deepEqual from 'deep-equal';

@Component({
  selector: 'tr-dynamic-player-iframe',
  templateUrl: './dynamic-player-iframe.component.html',
  styleUrls: ['./dynamic-player-iframe.component.css']
})
export class DynamicPlayerIframeComponent implements OnInit, OnChanges {
  constructor(private sanitizer: DomSanitizer) { }

  @Input() account: string;
  @Input() template: Template;
  @Input() sku: string;
  @Input() parameters: any;
  url: SafeResourceUrl;

  // parametersChangedStream: Subject<any> = new Subject<any>();
  // parametersChangedSubscription: Subscription;

  update(): void {
    this.generateUrl();
  }

  generateUrl(): void {
    if (this.account && this.sku && this.template && this.template.instanceId) {
      this.url =  this.sanitizer.bypassSecurityTrustResourceUrl('/assets/treepodia/player.html?' +
        'account=' + this.account +
        '&sku=' + encodeURIComponent(this.sku) +
        '&templateInstance=' + this.template.instanceId +
        '&parameters=' + encodeURIComponent(JSON.stringify(this.parameters)));
    } else {
      this.url = undefined;
    }
  }

  clean(): void {
    this.url = undefined;
  }

  ngOnInit() {
    // this.parametersChangedSubscription = this.parametersChangedStream
    //   .pipe(debounceTime(5000))
    //   .subscribe((value: any) => {
    //     // this.generateUrl();
    //     console.log('value changed: ' + value);
    //   });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parameters &&
      JSON.stringify(changes.parameters.currentValue) !== JSON.stringify(changes.parameters.previousValue)) {
      // this.parametersChangedStream.next(changes.parameters);
      this.generateUrl();
    }
  }
}
