import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Template} from '../models/template';
import {PagedParameterVariants} from '../models/paged-parameter-variants';
import {DynamicPlayerIframeComponent} from '../_components/dynamic-player-iframe/dynamic-player-iframe.component';
import {GoogleTargetingSelectionComponent} from '../_components/google-targeting-selection/google-targeting-selection.component';
import {CampaignsService} from '../services/campaigns.service';
import {AdGroupsService} from '../services/ad-groups.service';
import {TemplateService} from '../services/template.service';
import {AdvertsService} from '../services/adverts.service';
import {Router} from '@angular/router';
import {AlertServiceService} from '../services/alert-service.service';
import {AuthenticationService} from '../services/authentication-service';
import {ConfigService} from '../services/config.service';
import {TrpdModalServiceService} from '../services/trpd-modal-service.service';
import {TemplateParameterValue} from '../models/template-parameter-value';
import {TemplateParameter} from '../models/template-parameter';
import {CreateAdvertRequest} from '../models/create-advert-request';
import {GoogleTargeting} from '../models/google-targeting';
import {DatafeedProductsComponent} from '../_components/datafeed-products/datafeed-products.component';
import {DatafeedProduct} from '../models/datafeed-product';
import {ImagesInputFieldComponent} from '../_components/images-input-field/images-input-field.component';
import {CreateDatafeedAdvertsRequest} from '../models/create-datafeed-adverts-request';

@Component({
  selector: 'tr-add-datafeed-ads',
  templateUrl: './add-datafeed-ads.component.html',
  styleUrls: ['./add-datafeed-ads.component.css']
})
export class AddDatafeedAdsComponent implements OnInit {
  selectTemplateForm: FormGroup;

  templateParametersForm: FormGroup;

  templates: Template[];
  selectedTemplate: Template;
  selectedTemplateParameters: {};
  selectedTemplatePagedParameterVariants: PagedParameterVariants;
  tagsSplitter: string;
  @ViewChild('videoPlayer', {static: false})
  videoPlayer: DynamicPlayerIframeComponent;
  @ViewChild('datafeedProducts', {static: false})
  datafeedProducts: DatafeedProductsComponent;
  smartWizard: any;
  advertName: string;
  destinationUrl: string;
  loading: boolean;
  @ViewChild('googleTargetingSelection', {static: false})
  googleTargetingSelection: GoogleTargetingSelectionComponent;
  previewAdsPage: number;
  currentStep: number;
  previewVariantsPage: number;
  forcePreviewVersion: number;

  constructor(
    private formBuilder: FormBuilder,
    private campaignsService: CampaignsService,
    private adGroupService: AdGroupsService,
    private templatesService: TemplateService,
    private advertsService: AdvertsService,
    private router: Router,
    private alertService: AlertServiceService,
    private authenticationService: AuthenticationService,
    private config: ConfigService,
    private modalService: TrpdModalServiceService
  ) {
    this.getTemplates();
    this.selectedTemplateParameters = {};
    this.selectedTemplatePagedParameterVariants = new PagedParameterVariants();
    this.tagsSplitter = ',';
    this.forcePreviewVersion = 1;
  }

  getCurrentUser() {
    return this.authenticationService.currentUserValue;
  }

  loadSelectedTemplate(): boolean {
    const selectedTemplateInput = this.selectTemplateForm.get('selectedTemplate');
    const loadedTemplateCallback = (template) => {
      this.selectedTemplate = template;
      const controlsConfig = {};
      for (const param of this.selectedTemplate.parameters) {
        controlsConfig[param.name] = ['', Validators.required];

        if (!param.value && param.defaultValue && param.defaultValue.length > 0) {
          param.value = new TemplateParameterValue();
          param.value.parameterId = param.id;
          param.value.value = param.defaultValue;
        }
      }
      this.templateParametersForm = this.formBuilder.group(controlsConfig);

      this.previewAd();
    };

    if (selectedTemplateInput && selectedTemplateInput.value) {
      this.loading = true;
      this.templatesService.getTemplate(selectedTemplateInput.value.id).pipe().subscribe(template => {
        this.loading = false;
        loadedTemplateCallback(template);
      }, (e) => {
        this.loading = false;
        this.alertService.error(e, false);
      });
      return true;
    } else {
      Object.keys(this.selectTemplateForm.controls).forEach(field => { // {1}
        const control = this.selectTemplateForm.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
    return false;
  }

  hidePreview(): void {
    if (this.videoPlayer) {
      this.videoPlayer.clean();
    }
  }

  applyProductParameters(product: DatafeedProduct, rawValues: string[]) {
    const resultValues = [];
    for (const rawValue of rawValues) {
      if (rawValue === '{product.name}') {
        resultValues.push(product.name);
      } else
      if (rawValue === '{product.category}') {
        resultValues.push(product.category);
      } else
      if (rawValue === '{product.image.url}') {
        if (product.images && product.images.length > 0) {
          for (const image of product.images) {
            resultValues.push(image);
          }
        }
      } else {
        resultValues.push(rawValue);
      }
    }
    return resultValues;
  }

  previewAd() {
    if (this.selectedTemplate) {
      const product = this.datafeedProducts.getSelectedDatafeedProducts()[0];
      for (const parameter of this.selectedTemplate.parameters) {
        if (parameter.type === 'text'
          || parameter.type === 'image'
          || parameter.type === 'video'
          || parameter.type === 'checkbox'
          || parameter.type === 'select') {
          const strValue = parameter.value && parameter.value.value;
          const splittedRawValues = strValue ? strValue.split(this.tagsSplitter) : [undefined];
          const splittedValues = this.applyProductParameters(product, splittedRawValues);
          this.selectedTemplateParameters[parameter.name] = splittedValues[splittedValues.length - 1];
        }
      }
      // if (this.videoPlayer) {
      //   this.videoPlayer.update();
      // }
      this.forcePreviewVersion++;
    }
  }

  getTemplateParameters(product: DatafeedProduct): any {
    if (this.selectedTemplate) {
      const selectedTemplateParameters = {};
      product = product || this.datafeedProducts.getSelectedDatafeedProducts()[0];
      for (const parameter of this.selectedTemplate.parameters) {
        if (parameter.type === 'text'
          || parameter.type === 'image'
          || parameter.type === 'video'
          || parameter.type === 'checkbox'
          || parameter.type === 'select') {
          const strValue = parameter.value && parameter.value.value;
          const splittedRawValues = strValue ? strValue.split(this.tagsSplitter) : [undefined];
          const splittedValues = this.applyProductParameters(product, splittedRawValues);
          if (parameter.display) {
            selectedTemplateParameters[parameter.name] = splittedValues[splittedValues.length - 1];
          } else {
            selectedTemplateParameters[parameter.name] = splittedValues[0];
          }
        }
      }
      selectedTemplateParameters['__version__'] = this.forcePreviewVersion;
      return selectedTemplateParameters;
    }
  }

  generateParametersCombinations(templateParameters: TemplateParameter[], product: DatafeedProduct) {
    const result = [];
    const parameters = [];
    product = product || this.datafeedProducts.getSelectedDatafeedProducts()[0];

    const sortedTemplateParameters: TemplateParameter[] = templateParameters.slice().sort((a: TemplateParameter, b: TemplateParameter) => {
      return (b.optimizationOrder || 0) - (a.optimizationOrder || 0);
    });

    for (const parameter of sortedTemplateParameters) {
      const parameterValues = [];
      if (parameter.type === 'text'
          || parameter.type === 'image'
          || parameter.type === 'video'
          || parameter.type === 'checkbox'
          || parameter.type === 'select') {
        const strValue = parameter.value && parameter.value.value;
        const splittedRawValues = strValue ? strValue.split(this.tagsSplitter) : [undefined];
        const splittedValues = this.applyProductParameters(product, splittedRawValues);
        for (const splittedValue of splittedValues) {
          parameterValues[parameterValues.length] = {
            name: parameter.name,
            value: splittedValue
          };
        }
        parameters[parameters.length] = parameterValues;
      }
    }
    const parametersCombinations = parameters.reduce((a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w))), []));
    for (const parametersCombination of parametersCombinations) {
      const combinationObject = {};
      for (const combinationValue of parametersCombination) {
        combinationObject[combinationValue.name] = combinationValue.value;
      }
      result[result.length] = combinationObject;
    }
    return result;
  }

  viewMoreAd(modalId, product: DatafeedProduct) {
    this.previewVariantsPage = 1;
    this.selectedTemplatePagedParameterVariants.clear();
    this.modalService.open(modalId);

    if (this.selectedTemplate) {
      this.selectedTemplatePagedParameterVariants.setParameterVariants(
        this.generateParametersCombinations(this.selectedTemplate.parameters, product));
    }
  }

  closeModal(modalId) {
    this.selectedTemplatePagedParameterVariants.clear();
    this.modalService.close(modalId);
  }

  formatDateString(date: Date): string {
    return (date.getDate() < 10 ? '0' : '') + date.getDate()
      + '.' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1)
      + '.' + date.getFullYear();
  }

  ngOnInit(): void {
    this.videoPlayer = null;
    this.advertName = 'My advert ' + this.formatDateString(new Date());
    this.destinationUrl = null;

    this.selectTemplateForm = this.formBuilder.group({
      selectedTemplate: ['', Validators.required]
    });

    this.templateParametersForm = this.formBuilder.group({});
    //
    const onLeaveStepCallback = (stepData) => {
      const fromStep = stepData.fromStep;
      const toStep = stepData.toStep;
      if (fromStep === 3 && toStep === 4) {
        this.previewAdsPage = 0;
        this.hidePreview();
        return this.loadSelectedTemplate();
      } else
      if (fromStep === 1 && toStep === 2) {
        return this.googleTargetingSelection.isValid();
      } else
      if (fromStep === 2 && toStep === 3) {
        return this.datafeedProducts.validate();
      }
      return true;
    };

    const validateInput = () => {
      if (!this.googleTargetingSelection.validate()) {
        return false;
      }

      if (this.advertName === null || this.advertName.length === 0) {
        return false;
      }

      // if (this.destinationUrl === null || this.destinationUrl.length === 0) {
      //  return false;
      // }

      return true;
    };

    const submitAdvert = () => {
      const request = new CreateDatafeedAdvertsRequest();
      const selectedTargeting = this.googleTargetingSelection.getSelectedTargeting();
      request.googleCampaign = selectedTargeting.campaign;
      request.googleAdGroup = selectedTargeting.adGroup;
      request.advertName = this.advertName.trim();
      request.destinationUrl = this.destinationUrl;
      request.displayUrl = this.destinationUrl;
      request.templateId = this.selectedTemplate.id;
      request.parameters = this.selectedTemplate.parameters;
      request.products = this.datafeedProducts.getSelectedDatafeedProducts();

      this.setAdvertCreating(true);
      this.advertsService
        .addDatafeedAdverts(request)
        .subscribe(() => {
          this.setAdvertCreating(false);
          this.router.navigate(['/']);
        }, error => {
          this.setAdvertCreating(false);
          this.alertService.error('Failed to create Advert. Please try again later.', false);
          console.log(error);
        }, () => {
        });
    };

    const updateWizardButtons = (step) => {
      this.currentStep = step;
      this.updateWizardButtonsStyle(step);
    };

    this.smartWizard = ($('#wizard') as any).smartWizard({
      onLeaveStep(w, step) {
        return onLeaveStepCallback(step);
      },
      onShowStep(w, step) {
        updateWizardButtons(step.toStep);
      },
      labelFinish : 'Submit Advert',
      enableFinishButton : true,
      keyNavigation: false,
      onFinish(w, s, c) {
        if (validateInput()) {
          submitAdvert();
        }
      },
      onCancel() {
        this.router.navigate(['/']);
      }
    });

    $('.buttonPrevious').addClass('btn btn-light');
    $('.buttonNext').addClass('btn btn-success');
    $('.buttonFinish').addClass('btn btn-success buttonDisabled');
    $('.buttonCancel').addClass('btn btn-light');
  }

  updateWizardButtonsStyle(step): void {
    if (step === 5) {
      $('.buttonFinish').removeClass('buttonDisabled');
    } else {
      $('.buttonFinish').addClass('buttonDisabled');
    }
  }

  getTemplates() {
    this.templatesService.getTemplates().pipe().subscribe(templates => {
      this.templates = templates;
    }, (e) => {
      this.loading = false;
      this.alertService.error(e, false);
    });
  }

  onParameterChanged(parameter: TemplateParameter, value: string) {
    console.log('Parameter: ' + parameter.name + ', value: ' + value);
    if (!parameter.value) {
      parameter.value = new TemplateParameterValue();
      parameter.value.parameterId = parameter.id;
    }
    parameter.value.value = value;
  }

  selectTemplate(template: Template) {
    this.selectedTemplate = template;
  }

  onTargetingSelected(targeting: GoogleTargeting) {

  }

  setAdvertCreating(creating): void {
    this.loading = creating;
    if (this.currentStep === 5) {
      if (creating) {
        $('.buttonFinish').addClass('buttonDisabled');
        $('.buttonFinish').html('<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Submit Advert');
      } else {
        $('.buttonFinish').removeClass('buttonDisabled');
        $('.buttonFinish').html('Submit Advert');
      }
    }
  }

  addValue(parameter: TemplateParameter, value: string) {
    if (!parameter.value) {
      parameter.value = new TemplateParameterValue();
      parameter.value.parameterId = parameter.id;
      parameter.value.value = parameter.defaultValue;
    }
    parameter.value.value =  (parameter.value.value || '') + (parameter.value.value ? ',' : '') + value;
  }
}
