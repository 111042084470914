import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {User} from '../../models/user';
import {TemplateParameter} from '../../models/template-parameter';

@Component({
  selector: 'tr-videos-input-field',
  templateUrl: './videos-input-field.component.html',
  styleUrls: ['./videos-input-field.component.css']
})
export class VideosInputFieldComponent implements OnInit, OnChanges {

  constructor(private http: HttpClient) {
    this.loading = false;
  }

  @Input()
  account: User;
  @Input()
  value: string;
  @Input()
  parameter: TemplateParameter;
  @Output()
  changed = new EventEmitter<string>();

  videoNames: string[];
  loading: boolean;
  loadingProgress: number;

  removeImage(imageName: string) {
    const index: number = this.videoNames.indexOf(imageName);
    if (index !== -1) {
      this.videoNames.splice(index, 1);
      this.changed.emit(this.videoNames.join(','));
    }
  }

  addValue(value: string) {
    this.videoNames[this.videoNames.length] = value;
    this.changed.emit(this.videoNames.join(','));
  }

  getVideoUrl(fileName, w, h): string {
    if (fileName === '{product.image.url}') {
      return 'assets/images/product-images.png';
    }
    if (fileName.startsWith('http://') || fileName.startsWith('https://')) {
      return 'https://api.treepodia.com/media/services/GetVideoByUrl?u=' + encodeURIComponent(fileName) + '&fit=cover&w=' + w + '&h=' + h + '&format=mp4&d=2000';
    } else {
      return 'https://api.treepodia.com/media/services/GetVideo?f=' + encodeURIComponent(fileName) + '&fit=cover&w=' + w + '&h=' + h + '&format=mp4&d=2000';
    }
  }

  ngOnInit() {
    this.videoNames = this.value && this.value.length > 0 ? this.value.split(',') : [];
    this.loadingProgress = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
    if (changes.value && changes.value.currentValue) {
      this.videoNames = this.value && this.value.length > 0 ? this.value.split(',') : [];
    }
  }

  onFileSelected(files) {
    console.log(files);
    if (files && files.length > 0) {
      const file = files[0];
      const fileName = (this.account && this.account.account && this.account.account.length > 0 ? this.account.account + '/' : '') + Math.random().toString(36).substr(2, 9) + '_' + file.name;
      const type = file.type;
      // tslint:disable-next-line:max-line-length
      const uploadRequestUrl = 'https://us-central1-my-treepodia-services.cloudfunctions.net/GetImageSignedUploadUrl?contentType=' + encodeURIComponent(type)
        + '&filename=' + encodeURIComponent(fileName);
      console.log(uploadRequestUrl);
      this.loading = true;
      this.loadingProgress = 0;
      this.http.get<string>(uploadRequestUrl, {responseType: 'text' as 'json'}).subscribe((signedUploadUrl) => {
        console.log(signedUploadUrl);
        // const formData: FormData = new FormData();
        // formData.append('file', file, file.name);
        const req = new HttpRequest('PUT', signedUploadUrl, file, {
          reportProgress: true,
          headers: new HttpHeaders().set('Content-Type', type)
        });
        this.http.request(req).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / event.total);
            this.loadingProgress = percentDone;
            console.log('Uploaded: ' + percentDone + '%');
          } else if (event instanceof HttpResponse) {
            console.log(event);
            this.videoNames[this.videoNames.length] = fileName;
            this.changed.emit(this.videoNames.join(','));
            this.loading = false;
          }
        }, (error => {
          console.log(error);
          this.loading = false;
        }));
      }, error => {
        console.log(error);
        this.loading = false;
      });
    }
  }
}
