import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {GoogleTargeting} from '../../models/google-targeting';
import {GoogleCampaign} from '../../models/google-campaign';
import {GoogleAdGroup} from '../../models/google-ad-group';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CampaignsService} from '../../services/campaigns.service';
import {AdGroupsService} from '../../services/ad-groups.service';
import {AlertServiceService} from '../../services/alert-service.service';
import {Validable} from '../../services/validable';
import {CreateSmartCampaignComponent} from '../create-smart-campaign/create-smart-campaign.component';
import {Campaign} from '../../models/campaign';

@Component({
  selector: 'tr-google-targeting-selection',
  templateUrl: './google-targeting-selection.component.html',
  styleUrls: ['./google-targeting-selection.component.css']
})
export class GoogleTargetingSelectionComponent implements OnInit, Validable {
  campaigns: GoogleCampaign[];
  adGroups: GoogleAdGroup[];
  selectedCampaign: GoogleCampaign;
  selectedAdGroup: GoogleAdGroup;
  loading: boolean;
  selectCampaignForm: FormGroup;
  showCreateNewCampaign: boolean;
  cancelableCreateNewCampaign: boolean;
  showAdGroupsSelection: boolean;
  @ViewChild('createSmartCampaignComponent', {static: false})
  createSmartCampaignComponent: CreateSmartCampaignComponent;
  @Output()
  targetingSelected = new EventEmitter<GoogleTargeting>();

  NEW_CAMPAIGN_HOLDER = Object.assign( new GoogleCampaign(), {
    id: -1,
    name: 'Create new campaign'
  });

  constructor( private formBuilder: FormBuilder,
               private campaignsService: CampaignsService,
               private adGroupService: AdGroupsService,
               private alertService: AlertServiceService) {
  }

  onCampaignSelected(event) {
    if (this.selectedCampaign === this.NEW_CAMPAIGN_HOLDER) {
      this.createNewCampaign();
    } else {
      console.log(this.selectedCampaign);
      this.selectedAdGroup = null;
      this.getAdGroups();
    }
  }

  onAdGroupSelected(event) {
    console.log(this.selectedAdGroup);
    this.targetingSelected.emit(this.getSelectedTargeting());
  }

  getSelectedTargeting(): GoogleTargeting {
    if (this.selectedCampaign != null && (this.selectedAdGroup != null || !this.showAdGroupsSelection) && !this.showCreateNewCampaign) {
      const targeting = new GoogleTargeting();
      targeting.adGroup = this.selectedAdGroup;
      targeting.campaign = this.selectedCampaign;
      return targeting;
    } else if (this.showCreateNewCampaign) {
      const targeting = new GoogleTargeting();
      targeting.campaign = this.createSmartCampaignComponent.getGoogleCampaign();
      return targeting;
    }
  }

  updateShowCreateNewCampaign(): void {
    this.showCreateNewCampaign = !this.campaigns || this.campaigns.length === 0;
    this.cancelableCreateNewCampaign = this.campaigns.length > 0;
  }

  getCampaigns() {
    this.loading = true;
    this.campaignsService.getGoogleCampaigns().pipe().subscribe(campaigns => {
      this.loading = false;
      this.campaigns = campaigns;
      if (this.campaigns.length > 0) {
        this.selectedCampaign = this.campaigns[0];
        this.onCampaignSelected(null);
        // this.campaigns[this.campaigns.length] = this.NEW_CAMPAIGN_HOLDER;
      }
      this.updateShowCreateNewCampaign();
    }, (e) => {
      this.loading = false;
      this.alertService.error(e, false);
    });
  }

  getAdGroups() {
    if (this.selectedCampaign && this.showAdGroupsSelection) {
      this.loading = true;
      this.adGroupService.getGoogleAdGroups(this.selectedCampaign).pipe().subscribe(adGroups => {
        this.loading = false;
        this.adGroups = adGroups;
        if (this.adGroups.length > 0) {
          this.selectedAdGroup = this.adGroups[0];
          this.onAdGroupSelected(null);
        }
        this.updateShowCreateNewCampaign();
      }, (e) => {
        this.loading = false;
        this.alertService.error(e, false);
      });
    } else {
      this.adGroups = [];
    }
  }

  ngOnInit() {
    this.loading = false;
    this.getCampaigns();
    this.getAdGroups();
    this.selectCampaignForm = this.formBuilder.group({
      campaign: ['', Validators.required],
      adGroup: ['']
    });
  }

  createNewCampaign(): void {
    this.showCreateNewCampaign = true;
    this.cancelableCreateNewCampaign = this.campaigns.length > 0;
    this.selectedCampaign = undefined;
  }

  cancelCreateNewCampaign() {
    this.showCreateNewCampaign = false;
    this.cancelableCreateNewCampaign = this.campaigns.length > 0;
    this.selectedCampaign = this.campaigns[0];
  }

  validate(): boolean {
    if (this.showCreateNewCampaign) {
      if (this.createSmartCampaignComponent.isValid()) {
        this.selectedCampaign = this.createSmartCampaignComponent.getGoogleCampaign();
      }
      return this.createSmartCampaignComponent.isValid();
    } else {
      this.selectCampaignForm.markAllAsTouched();
      return !this.selectCampaignForm.invalid;
    }
  }

  isValid(): boolean {
    return this.validate();
  }
}
