import {TemplateParameter} from './template-parameter';
import {AdGroup} from './ad-group';
import {GoogleCampaign} from './google-campaign';

export class CreateAdvertRequest {
  advertName: string;
  googleCampaign: GoogleCampaign;
  googleAdGroup: AdGroup;
  templateId: number;
  destinationUrl: string;
  displayUrl: string;
  parameters: TemplateParameter[];
}
