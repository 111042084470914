import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Advert} from '../../models/advert';
import {AdvertStatus} from '../../models/advert-status.enum';
import {AdsManager} from '../ads-manager';
import {ConfigService} from '../../services/config.service';
import {AdvertOptimizationStatus} from '../../models/advert-optimization-status.enum';
import {AdvertsService} from '../../services/adverts.service';
import {Video} from '../../models/video';
import {VideoService} from '../../services/video.service';
import {VideoParameter} from '../../models/video-parameter';
import {ProductDatafeedService} from '../../services/product-datafeed.service';
import {Sort} from '@angular/material/sort';
import {Campaign} from '../../models/campaign';
import {CampaignStatus} from '../../models/campaign-status.enum';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialogComponent} from '../../_components/message-dialog/message-dialog.component';
import {ConfirmDialogComponent} from '../../_components/confirm-dialog/confirm-dialog.component';
import {Router} from '@angular/router';

@Component({
  selector: 'tr-ads-list',
  templateUrl: './ads-list.component.html',
  styleUrls: ['./ads-list.component.css']
})
export class AdsListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  adverts: Advert[];
  @Input()
  adsManager: AdsManager;

  filteredAdverts: Advert[];
  selection = new SelectionModel<Advert>(true, []);

  campaigns: Campaign[];

  hasDatafeedConfiguration: boolean;
  showFilter: string;
  campaignFilter: Campaign;

  advertsPage: number;

  constructor(private config: ConfigService,
              private advertsService: AdvertsService,
              private videoService: VideoService,
              private datafeedService: ProductDatafeedService,
              private elem: ElementRef,
              public dialog: MatDialog,
              private router: Router) { }

  getAdvertStatusClass(advert: Advert): string {
    switch (advert.status) {
      case AdvertStatus.Pending:
        return 'badge badge-secondary';
      case AdvertStatus.Enabled:
        return advert.campaign && (advert.campaign.status === 2 || advert.campaign.status === 3) ? 'badge badge-secondary' : 'badge badge-success';
      case AdvertStatus.Paused:
        return 'badge badge-secondary';
    }
  }

  getAdvertStatusText(advert: Advert): string {
    switch (advert.status) {
      case AdvertStatus.Pending:
        return 'Pending';
      case AdvertStatus.Enabled:
        if (advert.campaign && advert.campaign.status === 2) {
          return 'Campaign disabled';
        } else
        if (advert.campaign && advert.campaign.status === 3) {
          return 'Campaign removed';
        }
        return 'Enabled';
      case AdvertStatus.Paused:
        return 'Paused';
    }
  }

  getAdvertStatusStr(advert: Advert): string {
    switch (advert.status) {
      case AdvertStatus.Pending:
        return 'Pending';
      case AdvertStatus.Enabled:
          return 'Enabled';
      case AdvertStatus.Paused:
        return 'Paused';
    }
  }

  getAdvertMoreData(moreData: HTMLTableRowElement, advert: Advert): void {
    if (moreData.style.display === 'none') {
      moreData.style.display = '';
      this.advertsService.getAdvertMoreData(advert).subscribe((advertMoreData: Advert) => {
        advert.advertAdjustmentLog = advertMoreData.advertAdjustmentLog;
        advert.videos = advertMoreData.videos;
        advert.algorithm = advertMoreData.algorithm;
        advert.parameters = advertMoreData.parameters;
      });
    } else {
      moreData.style.display = 'none';
    }
  }

  getOptimizationStatusStr(advert: Advert): string {
    if (advert.status === AdvertStatus.Pending) {
      return '-';
    }
    switch (advert.optimizationStatus) {
      case AdvertOptimizationStatus.Learning:
        return 'Learning';
      case AdvertOptimizationStatus.Completed:
        return 'Completed';
      case AdvertOptimizationStatus.Error:
        return 'Error';
    }
    return '-';
  }

  doDeleteAdvert(advert: Advert) {
    if (this.adsManager) {
      this.adsManager.deleteAdvert(advert);
    }
  }

  enableAdvert(advert: Advert) {
    if (this.adsManager) {
      this.adsManager.enableAdvert(advert);
    }
  }

  pauseAdvert(advert: Advert) {
    if (this.adsManager) {
      this.adsManager.pauseAdvert(advert);
    }
  }

  ngOnInit() {
    this.advertsPage = 1;
    this.showFilter = 'All';
    this.datafeedService.hasDatafeedConfiguration().subscribe((hasDatafeedConfiguration) => {
      this.hasDatafeedConfiguration = hasDatafeedConfiguration;
    });
    this.filter();
    this.detectCampaigns();
    this.campaignFilter = this.campaigns[0];
  }

  getVideoRowStyleClass(advert: Advert, video: Video) {
    switch (video.status) {
      case 'UpToDate':
        if (advert.algorithm && advert.algorithm.videos) {
          return advert.algorithm.videos.find(v => v.id === video.id) ? 'table-success' : '';
        }
        return video.impressions > 0 || video.interactions > 0 ? 'table-success' : '';
      case 'Disabled':
        return 'table-danger';
      default:
        return '';
    }
  }

  showVideoVariation(videoVariationInfo: HTMLTableRowElement, video: Video) {
    if (videoVariationInfo.style.display === 'none') {
      videoVariationInfo.style.display = '';
      video.template = undefined;
      this.videoService.getVideo(video).subscribe((videoMoreData: Video) => {
        video.videoParameters = videoMoreData.videoParameters;
        video.template = videoMoreData.template;
        video.sku = videoMoreData.sku;
        video.accountUuid = videoMoreData.accountUuid;
        if (videoMoreData.videoParameters) {
          video.playerVideoParameters = {};
          for (const videoParameter of videoMoreData.videoParameters) {
            video.playerVideoParameters[videoParameter.name] = videoParameter.value;
          }
        }

      });
    } else {
      videoVariationInfo.style.display = 'none';
    }
  }

  getInteractionRate(advert: Advert) {
    return advert.adGroup.impressions === 0 ? 0 : (advert.adGroup.clicks || -1) / advert.adGroup.impressions;
  }

  sortAds(sort: Sort) {
    console.log(sort);
    if (!sort.active || sort.direction === '') {
      return;
    }

    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return a !== b ? (a < b ? -1 : 1) * (isAsc ? 1 : -1) : 0;
    }

    this.adverts.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'status': return compare(a.status, b.status, isAsc) || compare(a.id, b.id, isAsc);
        case 'template': return compare(a.thumbnail, b.thumbnail, isAsc) || compare(a.id, b.id, isAsc);
        case 'name': return compare(a.name, b.name, isAsc) || compare(a.id, b.id, isAsc);
        case 'campaign': return compare(a.campaign.name, b.campaign.name, isAsc) || compare(a.id, b.id, isAsc);
        case 'optimization': return compare(this.getOptimizationStatusStr(a), this.getOptimizationStatusStr(b), isAsc) || compare(a.id, b.id, isAsc);
        case 'impressions': return compare(a.adGroup.impressions, b.adGroup.impressions, isAsc) || compare(a.id, b.id, isAsc);
        case 'interactions': return compare(a.adGroup.clicks, b.adGroup.clicks, isAsc) || compare(a.id, b.id, isAsc);
        case 'interactionRate': return compare(this.getInteractionRate(a), this.getInteractionRate(b), isAsc) || compare(a.id, b.id, isAsc);
        default: return 0;
      }
    });
    this.filter();
  }

  detectCampaigns(): void {
    const campaigns: Campaign[] = [];
    campaigns.push({id: -1, name: 'All', status: -1});
    const map = new Map();
    this.adverts.map(a => a.campaign).forEach(c => {
      if (!map.has(c.id)) {
        map.set(c.id, c);
        campaigns.push(c);
      }
    });
    this.campaigns = campaigns;
  }

  filter() {
    this.selection.clear();
    this.advertsPage = 1;
    this.filteredAdverts = this.adverts.filter(a => {
      switch (this.showFilter) {
        case 'Enabled': return a.status === AdvertStatus.Enabled && a.campaign.status === CampaignStatus.Enabled;
        case 'Pending': return a.status === AdvertStatus.Pending;
        case 'Paused': return a.status === AdvertStatus.Paused;
      }
      return true;
    }).filter(a => !this.campaignFilter || this.campaignFilter.id === -1 || this.campaignFilter.id === a.campaign.id);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredAdverts.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.filteredAdverts.forEach(row => this.selection.select(row));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.adverts) {
      this.filter();
    }
  }

  ngAfterViewInit(): void {
    const elements = this.elem.nativeElement.querySelectorAll('.mat-sort-header-button');
    elements.forEach((button) => {
      (button as HTMLElement).style.outline = '0';
    });
  }

  enableAdverts() {
    if (this.selection.isEmpty()) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title : 'Enable Adverts',
          message: 'Please select adverts to enable'
        }
      });
      return;
    }

    if (this.adsManager) {
      const adverts = this.selection.selected;
      this.adsManager.enableAdverts(adverts);
    }
  }

  pauseAdverts() {
    if (this.selection.isEmpty()) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title : 'Enable Adverts',
          message: 'Please select adverts to pause'
        }
      });
      return;
    }

    if (this.adsManager) {
      const adverts = this.selection.selected;
      this.adsManager.pauseAdverts(adverts);
    }
  }

  deleteAdverts() {
    if (this.selection.isEmpty()) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title : 'Enable Adverts',
          message: 'Please select adverts to delete'
        }
      });
      return;
    }

    if (this.adsManager) {
      const adverts = this.selection.selected;
      this.adsManager.deleteAdverts(adverts);
    }
  }

  resetAdvertLearnings() {
    if (this.selection.isEmpty()) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title : 'Adverts',
          message: 'Please select adverts to reset'
        }
      });
      return;
    }

    if (this.adsManager) {
      const adverts = this.selection.selected;
      this.adsManager.resetAdvertLearnings(adverts);
    }
  }

  getSimilarAdverts(advert: Advert) {
    return this.filteredAdverts.filter(a => a.groupId === advert.groupId && a.campaign.id === advert.campaign.id);
  }

  editAdvert() {
    if (this.selection.isEmpty()) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title : 'Edit Advert',
          message: 'Please select adverts'
        }
      });
      return;
    }

    const otherSimilarAdverts = this.getSimilarAdverts(this.selection.selected[0]);
    const moreSimilarAdvertsCount = otherSimilarAdverts.length - this.selection.selected.length;
    if (moreSimilarAdvertsCount > 0) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Edit Adverts',
          message: moreSimilarAdvertsCount === 1 ? 'There is another ad like this, do you want to edit it at the same time?' : 'There are ' + moreSimilarAdvertsCount + ' more similar ads, do you want to edit them at the same time?',
          confirmButton: 'Yes',
          declineButton: 'No',
          cancelButton: 'Cancel',
          onConfirm: () => {
            for (const otherAdvert of otherSimilarAdverts) {
              this.selection.select(otherAdvert);
            }
            this.router.navigate(['advert/' , this.selection.selected.map(a => a.id).join(',')]);
          },
          onDecline: () => {
            this.router.navigate(['advert/' , this.selection.selected.map(a => a.id).join(',')]);
          }
        }
      });
    } else {
      this.router.navigate(['advert/' , this.selection.selected.map(a => a.id).join(',')]);
    }
  }

  allowEditSelectedAdverts() {
    if (this.selection.selected.length === 0) {
      return false;
    }
    for (const selectedAdvert of this.selection.selected) {
      if (selectedAdvert.groupId !== this.selection.selected[0].groupId) {
        return false;
      }
    }
    return true;
  }

  showSelectSimilarAdvert() {
    return this.selection.selected.length === 1 && this.selection.selected[0].groupId.startsWith('group');
  }

  selectSimilarAds() {
    if (this.selection.selected.length === 1 && this.selection.selected[0].groupId.startsWith('group')) {
      this.getSimilarAdverts(this.selection.selected[0]).forEach(advert => {
        this.selection.select(advert);
      });
    }
  }
}
