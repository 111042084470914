import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '../../models/location';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {LocationService} from '../../services/location.service';
import {GoogleTargeting} from '../../models/google-targeting';
import {Validable} from '../../services/validable';
import {CreateSmartCampaignComponent} from '../create-smart-campaign/create-smart-campaign.component';

@Component({
  selector: 'tr-location-input-field',
  templateUrl: './location-input-field.component.html',
  styleUrls: ['./location-input-field.component.css']
})
export class LocationInputFieldComponent implements OnInit, Validable {
  filteredLocations: Location[] = [];
  locationForm: FormGroup;
  isLoading = false;

  @Output()
  locationSelected = new EventEmitter<Location>();

  @ViewChild('locationInputField', {static: false})
  locationInputField: any;

  constructor(private fb: FormBuilder, private locationService: LocationService) { }

  ngOnInit() {
    this.locationForm = this.fb.group({
      locationInput: [null, Validators.required]
    });

    this.locationForm
      .get('locationInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap((value: Location) => this.locationService.search({
            name: value.name || JSON.stringify(value)
           }).pipe(finalize(() => this.isLoading = false))
        )
      )
      .subscribe(locations => this.filteredLocations = locations);
  }

  displayFn(location: Location) {
    if (location) { return location.canonicalName || location.name + ', ' + location.countryCode; }
  }

  getSelectedValue(): Location {
    return this.locationForm.get('locationInput').value as unknown as Location;
  }

  isValid(): boolean {
    this.locationForm.markAllAsTouched();
    return this.locationForm.get('locationInput').value !== null;
  }

  onLocationSelected() {
    this.locationSelected.emit(this.getSelectedValue());
  }

  clear() {
    this.locationForm.get('locationInput').patchValue(undefined, {onlySelf: true, emitEvent: false});
    // this.locationForm.get('locationInput').markAsUntouched();
  }
}
